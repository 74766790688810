import React from "react";

const Team = () => {
  return (
    <div className="mask w-slider-mask">
      <div className="slide-1 w-slide">
        <div className="slide-wapper">
          <div className="slide-card">
            <div className="card-no">
              01<span className="number-red">/04</span>
            </div>
            <div className="card-image"></div>
            <h3 className="heading-2">
              Pika <br />
              Founder
            </h3>
            <p className="slide-paragraph">
              Pika has been in the crypto industry for over 6 years starting out
              as a bitcoin trader and moved over to the BSC chain in late
              December 2020
            </p>
            <a href="/" className="slide-button w-button">
              Explore
            </a>
          </div>
          <div className="slide-card">
            <div className="card-no">
              02<span className="number-red">/04</span>
            </div>
            <div className="card-image _2"></div>
            <h3 className="heading-2">
              Altpl <br />
              Backend Dev
            </h3>
            <p className="slide-paragraph">
              Altpl is our backend developer who is in charge of our smart
              contract creations and has worked with many other big projects in
              his time.
            </p>
            <a href="/" className="slide-button w-button">
              Explore
            </a>
          </div>
          <div className="slide-card _3">
            <div className="card-image _3 _4"></div>
            <h3 className="heading-2">
              Geekray <br />
              Designer
            </h3>
            <p className="slide-paragraph">
              Geekray has been designing Chibi and anime for over 5 years and is
              best in class at what he does. His transferrable skills into the
              NFT world makes him a core part to our project
            </p>
            <a href="/" className="slide-button w-button">
              Explore
            </a>
            <div className="card-no">
              03<span className="number-red">/04</span>
            </div>
          </div>
          <div className="slide-card _3">
            <div className="card-image _4"></div>
            <h3 className="heading-2">
              Amit
              <br />
              Frontend Developer
            </h3>
            <p className="slide-paragraph">
              Amit is a smart frontend engineer and has been in the blockchain
              tech development for a few years. He has a great knack for high
              quality user interface. His love for anime and its wide ray of
              application in NFTs makes him an ideal candidate for this role.
            </p>
            <a href="/" className="slide-button w-button">
              Explore
            </a>
            <div className="card-no">
              04<span className="number-red">/04</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
