import React from "react";
import { Twitter, Telegram } from "@styled-icons/boxicons-logos";
import "./Footer.css";

const Item = ({ children, path = "#" }) => {
  return (
    <div
      style={{
        display: "inline-block",
        marginRight: 50,
        fontWeight: "bold",
      }}
    >
      <a href={path}>{children}</a>
    </div>
  );
};

const Footer = () => {
  return (
    <footer
      className="footer"
      style={{
        textAlign: "center",
        backgroundColor: "#0e0d2d",
        zIndex: 10,
        position: "relative",
      }}
    >
      <div>
        <Item>Medium</Item>
        <Item>Docs</Item>
        <Item>Contracts</Item>
        <Item>Audit</Item>
        <div className="social">
          <a href="/" style={{ display: "inline-block" }} className="icon">
            <Twitter size="24" color="#979797" />
          </a>
          <a href="/" style={{ display: "inline-block" }} className="icon">
            <Telegram size="24" color="#979797" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
