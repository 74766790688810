import "./PresaleFormButton.css";

function PresaleFormButton() {
  return (
    <div
      onClick={() => {
        alert("Resale Form");
      }}
    >
      Pre-Sale Form
    </div>
  );
}

export default PresaleFormButton;
