import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import ConnectWalletButton from "./ConnectWalletButton";
import PresaleFormButton from "./PresaleFormButton";
import Footer from "./Footer";
import Team from "./Team";
// import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <ConnectWalletButton />
  </React.StrictMode>,
  document.getElementById("connect-wallet-button")
);

ReactDOM.render(
  <React.StrictMode>
    <PresaleFormButton />
  </React.StrictMode>,
  document.getElementById("pre-sale-form-button")
);
ReactDOM.render(
  <React.StrictMode>
    <Footer />
  </React.StrictMode>,
  document.getElementById("footer")
);
ReactDOM.render(
  <React.StrictMode>
    <Team />
  </React.StrictMode>,
  document.getElementById("team")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
